import mixin from '@c/mixins'
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import { sendMsg, register } from '@api'
export default {
    name: 'Register',
    mixins: [mixin],
    components: {
        simpleHeader,
        simpleFooter
    },
    data() {
        return {
            ruleForm: {
                mobile: '',
                password: '',
                code: ''
            }
        }
    },
    methods: {
        // 发送短信
        async sendMsg() {
            this.$refs.ruleForm.validateField('mobile', async error => {
                console.log(error)
                if (!error) {
                    const res = await sendMsg({
                        mobile: this.ruleForm.mobile,
                        type: 2
                    })
                    console.log('res', res)
                    this.$notify({ title: '提示', type: res.detail.status === 1 ? 'success' : 'error', message: res.msg || '短信验证码已发送到您的手机，请注意查收', position: 'bottom-right' })
                }
            })
        },
        async onSubmit() {
            this.$refs['ruleForm'].validate(async valid => {
                if (valid) {
                    const res = await register(this.ruleForm)
                    console.log('res', res)
                    const msg = [
                        '注册成功',
                        '注册失败',
                        '该手机号已注册'
                    ]
                    const status = res.detail.status
                    this.$notify({ title: '提示', type: status === 1 ? 'success' : 'error', message: res.msg || msg[status], position: 'bottom-right' })
                    if (res.detail.status !== 1) {
                        this.$refs['ruleForm'].resetFields()
                    }
                }
            })
        }
    }
}
